<template>
  <div class="content">
    <div class="screenView useCases">
      <el-form :model="form" class="form-inline" inline>
        <el-form-item>
          <el-select
            v-model="form.industry"
            placeholder="行業"
            @change="getData(true)"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) of industryList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.product"
            placeholder="產品"
            @change="getData(true)"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) of productList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.region"
            placeholder="地區"
            @change="getData(true)"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) of regionList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <el-row>
        <template v-if="list.length != 0">
          <div
            v-for="(item, index) of list"
            :key="index"
            :class="index > 2 ? 'min_col' : ''"
          >
            <el-col :span="24" v-if="index == 0">
              <router-link
                :to="`/use_cases_details?id=${item.id}`"
                class="item item_one"
              >
                <div class="imgView">
                  <img :src="item.attachment" alt="" />
                </div>
                <div class="txtView">
                  <div class="upView">
                    <time>{{ item.years }}</time>
                    <span>{{ item.sub_title }}</span>
                  </div>
                  <h3>{{ item.title }}</h3>
                  <div class="txtCon">{{ item.description }}</div>
                </div>
              </router-link>
            </el-col>
            <el-col :span="8" v-else-if="index == 1">
              <router-link
                :to="`/use_cases_details?id=${item.id}`"
                class="item item_two"
              >
                <div class="imgView">
                  <img :src="item.attachment" alt="" />
                </div>
                <div class="txtView">
                  <div class="upView">
                    <time>{{ item.years }}</time>
                    <span>{{ item.sub_title }}</span>
                  </div>
                  <h3>{{ item.title }}</h3>
                </div>
              </router-link>
            </el-col>
            <el-col :span="16" v-else-if="index == 2" class="dr">
              <router-link
                :to="`/use_cases_details?id=${item.id}`"
                class="item item_three"
              >
                <div class="imgView">
                  <img :src="item.attachment" alt="" />
                </div>
                <div class="txtView">
                  <div class="upView">
                    <time>{{ item.years }}</time>
                    <span>{{ item.sub_title }}</span>
                  </div>
                  <h3>{{ item.title }}</h3>
                </div>
              </router-link>
            </el-col>
            <el-col :span="24" v-else>
              <router-link
                :to="`/use_cases_details?id=${item.id}`"
                class="item item_four"
              >
                <div class="imgView">
                  <img :src="item.attachment" alt="" />
                </div>
                <div class="txtView">
                  <div class="upView">
                    <time>{{ item.years }}</time>
                    <span>{{ item.sub_title }}</span>
                  </div>
                  <h3>{{ item.title }}</h3>
                </div>
              </router-link>
            </el-col>
          </div>
        </template>
        <empty-data icon-size="60px" font-size="16px" v-else />
      </el-row>
      <el-button
        class="see_more"
        @click="getData"
        v-if="list.length < total"
        round
        >查看更多</el-button
      >
      <el-button class="see_more" @click="getData" v-else disabled round
        >没有更多了</el-button
      >
    </div>
    <div class="subscribeNewsView">
      <subscribe-news />
    </div>
  </div>
</template>

<script>
import subscribeNews from "@/components/subscribeNews";
export default {
  name: "UseCases",
  components: {
    subscribeNews,
  },
  data() {
    return {
      industryList: [],
      productList: [],
      regionList: [],
      form: {
        industry: "",
        product: "",
        region: "",
      },
      list: [],

      pageIndex: 1,
      pageSize: 10,
      total: 0,
    };
  },
  mounted() {
    this.getScreen();
    this.getData();
  },
  methods: {
    /**
     * 获取筛选项
     */
    getScreen() {
      this.$http.use_cases_screen().then((res) => {
        if (res.code == 200) {
          this.industryList = res.data.case_industry;
          this.productList = res.data.product;
          this.regionList = res.data.case_area;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /**
     * 获取列表数据
     */
    getData(update) {
      if (update) {
        this.pageIndex = 1;
        this.list = [];
      }
      let page = {
        page: this.pageIndex,
        limit: this.pageSize,
      };
      let screen = {
        industry_id: this.form.industry,
        product_id: this.form.product,
        area_id: this.form.region,
      };
      this.$http.casesList({ ...page, ...screen }).then((res) => {
        if (res.code == 200) {
          this.list = this.list.concat(res.data.data);
          this.total = res.data.total;
          this.pageIndex++;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/news.scss";
.main {
  .item {
    &:hover {
      .txtView {
        h3 {
          color: #0075c1;
        }
      }
    }
  }
}
/deep/ .useCases {
  @include respond-to(sm) {
    padding: 15px;
    margin: 0 !important;
    .el-form {
      flex-direction: column;
      .el-form-item {
        width: 100%;
        margin: 0 0 10px 0 !important;
      }
    }
  }
}
</style>